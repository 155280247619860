import moment from 'moment'
import React from 'react'
import { useRouter } from 'next/dist/client/router'
// import { isLocale } from '../translations/types'

export const LocaleContext = React.createContext({
  locale: 'en',
  setLocale: () => null
})

const availableLocales = ['fr', 'en', 'de', 'es']

const handleRouteChange = (url, router, locale) => {
  if(typeof window === 'undefined') return
  if (!url.startsWith(`/${locale}`)) {
    availableLocales.forEach((l) => {
      while (url.startsWith(`/${l}`)) {
        url = url.replace(`/${l}`, `/`)
      }
    })
    url = `/${locale}${url}`
    // remove double slashes
    url = url.replace(/\/{2,}/g, '/')
    if (router.asPath !== url) {
      router.push(url);
    }
  }
};

const getStoredLocale = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('locale')
  }
  return null
}

export const LocaleProvider = ({ lang, children }) => {
  const [locale, setLocale] = React.useState(getStoredLocale() || lang)
  const [defaultLocale, setDefaultLocale] = React.useState('fr')
  const router = useRouter()

  React.useEffect(() => {
    // if current url doesn't contain locale, redirect to locale-prefixed url
    if (!router.asPath.startsWith(`/${locale}`)) {
      handleRouteChange(router.asPath, router, locale)
    }
  }, [locale]);

  // store the preference
  React.useEffect(() => {
    if (locale !== localStorage.getItem('locale')) {
      localStorage.setItem('locale', locale)
    }
    if (locale == 'fr') moment.locale('fr-FR')
    else moment.locale('en-US')
  }, [locale])

  // sync locale value on client-side route changes
  React.useEffect(() => {
    setLocale(router.locale)
  }, [router.locale])


  return (
    <LocaleContext.Provider value={{ locale, setLocale, defaultLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}
